import { Apps } from '../app/core/enums';

export const environment = {
  production: true,
  app: Apps.Manager,
  isSW: false,
  brand: 'CX',
  apiUrl: 'https://api.backend.cinetixx.com',
  cloudFrontUrl: 'https://images.cinetixx.com',
  screenerAssetsUrl: 'https://screener-assets.cinetixx.com',
  adminUrl: 'https://admin.cinetixx.de',
  appUrl: 'https://manager.cinetixx.de',
  fallbackLanguage: 'de',
  movieDataBaseResultsPage: 50,
  servicesDateFormat: 'YYYY-MM-DD',
  servicesDateTimeFormat: 'YYYY-MM-DD HH:mm',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultTimeFormat: 'HH:mm',
  version: 'v4.33.3',
  wsUrl: 'https://api.backend.cinetixx.com/signalr',
  oldWsUrl: 'https://ws.cinetixx.com',
  versionPrefix: '',
  dsn: "https://012c45a4bfa034a20ee3a5bffd682787@o4508816761225216.ingest.de.sentry.io/4508816982409296",
  screenerUrl: 'https://tube.cinetixx.de',
  screenerAwsLink: 'https://screener-assets.cinetixx.com',
  postersLink: 'https://images.cinetixx.com/posters',
  fullcalendarLicenseKey: '0972065687-fcs-1727420918'
};
